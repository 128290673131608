
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'VideoContent1And2',
    mixins: [cms],
    data() {
        return {
            tab: 0,
            introductionTab: 0,
        }
    },
    computed: {
        ...mapGetters({
            introductionVideoContents: 'cms/introductionVideoContents',
        }),
    },
    methods: {
        setGlobalTabVal(val) {
            this.tab = val
            this.introductionTab = 0
        },
    },
}
